import React from "react"
import SEO from "../components/seo"
import FormCareers from "../components/forms/formCareers"

const CareersPage = () => (
  <main>
    <SEO
      title="Lavora con noi"
      description="Raccontaci chi sei."
    />
    <FormCareers
      title="Lavora con noi"
    />
  </main>

);

export default CareersPage;
